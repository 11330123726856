<template>
  <div class="page">
    <div class="header">
      <b-link class="menu" @click="current = 0" :class="current == 0 ? 'menu-active' : ''"
        >系统通知</b-link
      >
      <b-link class="menu" @click="current = 1" :class="current == 1 ? 'menu-active' : ''"
        >评论与@</b-link
      >
    </div>
    <div class="list" v-show="current == 0">
      <div class="list-item" v-for="(item,i) in list" :key="i">
        <div class="area">
          <img src="../../../static/images/mf.png" class="img" />
        </div>
        <div class="center">
          <div class="nickname">美蜂名医</div>
          <div class="message">{{item.title}}</div>
        </div>
        <div class="right-area">{{$timeFormat(item.sendtime, 'y.mm.dd hh:MM')}}</div>
      </div>
      <empty v-show="list.length == 0"></empty>
    </div>
    <div class="list" v-show="current == 1">
      <div class="list-item" v-for="(item, i) in chats" :key="i">
        <div class="area">
          <img :src="$host+item.owner_avatar" class="img" />
        </div>
        <div class="center">
          <div class="nickname">{{item.from_user_nickname?item.from_user_nickname:'未知用户'}}</div>
          <div class="message">{{'对你的科普内容进行'+item.type}}</div>
        </div>
        <div class="right-area">{{$timeFormat(item.create_time, 'y.mm.dd hh:MM')}}</div>
      </div>
      <empty v-show="chats.length == 0"></empty>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      params: {
        limit: 6,
        page: 1,
        user_ident: "doctors",
        id: "",
      },
      total: 0,
      chats: [],
      current: 0,
    };
  },
  mounted() {
    this.getMessage();
    this.getChats();
  },
  methods: {
    getMessage() {
      this.params.id = JSON.parse(this.$cache.getCache("doctorInfo")).id;
      this.$request.message(this.params).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.list = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getChats() {
      this.$request.chats(this.params).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.chats = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 910px;
  padding: 10px 0;
  min-height: 620px;
  background: #ffffff;
}
.header {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  height: 69px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  .menu {
    text-decoration: none;
    margin: 0 20px;
    font-size: 18px;
    color: #666;
    font-weight: bolder;
  }
  .menu-active {
    color: #fd9852;
  }
}
.list {
  padding: 0 40px;
  .list-item {
    height: 102px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    margin-top: 18px;
    .area {
      width: 62px;
      height: 62px;
      margin-right: 28px;
    }
    .center {
      width: 550px;
      .nickname {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
      .message {
        font-size: 16px;
        color: #999;
      }
    }
    .right-area {
      text-align: right;
      font-size: 14px;
      color: #999;
      padding-left: 20px;
    }
    .img {
      width: 62px;
      height: 62px;
      border-radius: 50%;
    }
  }
}
</style>
